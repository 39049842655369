import {UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client-ts';
import {env} from './EnvironmentConfig';

interface extendedUserManagerSettings extends UserManagerSettings {
  client_id: string;
  response_type: string;
  scope: string;
  authority: string;
  redirect_uri: string;
  client_secret: string;
  userStore?: WebStorageStateStore;
  automaticSilentRenew: boolean;
  // accessTokenExpiringNotificationTimeInSeconds: number;
  // filterProtocolClaims: boolean;
  // loadUserInfo: boolean;
  // ui_locales: string;
  // ui_locales_supported: string[];
  extraTokenParams?: Record<string, unknown>;
}
const config: extendedUserManagerSettings = {
  authority: env.API_ENDPOINT_URL,
  client_id: 'sayyad-portal',
  redirect_uri: 'http://localhost:3000/sign-in',
  //response_type: 'code',
  response_type: 'id_token token',
  silent_redirect_uri: `http://localhost:3000/silent-callback.html`,
  scope: 'profile openid sayyad-api sayyad-shared-api offline_access',
  client_secret: '299D56FA-B47B-5199-BA61-B298D431C318',
  userStore: new WebStorageStateStore({store: window.localStorage}),
  automaticSilentRenew: true,
  //   accessTokenExpiringNotificationTime: 60,
  // accessTokenExpiringNotificationTimeInSeconds: 60,
  // filterProtocolClaims: true,
  // loadUserInfo: true,
  // ui_locales: 'ar',
  // ui_locales_supported: ['ar'],

  extraTokenParams: {
    Lang: localStorage?.getItem('persist:root')
      ? JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.local)
          .language
      : 'en-US',
  },
};

export const userManager = new UserManager(config);

export const isAuthenticated = () => {
  const oidcStorage = JSON.parse(
    localStorage.getItem(`oidc.user:${env.API_ENDPOINT_URL}:sayyad-portal`),
  );
  return !!oidcStorage && !!oidcStorage.access_token;
};
export const oidcStorage = JSON.parse(
  localStorage.getItem(`oidc.user:${env.API_ENDPOINT_URL}:sayyad-portal`),
);
export const userData = () => {
  const data = JSON.parse(
    localStorage.getItem(`oidc.user:${env.API_ENDPOINT_URL}:sayyad-portal`),
  );
  return data;
};
